$(document).ready(function(){
	/* ---скрипт плавной прокрутки к якорю--- */  
  	$(".scroll").on("click", function(e){
			var anchor = $(this);
			if (window.innerWidth > 991) {
				$('html, body').stop().animate({
				scrollTop: $(anchor.attr('href')).offset().top - 82
				}, 777);
			} else {
				$('html, body').stop().animate({
					scrollTop: $(anchor.attr('href')).offset().top - 68
				}, 777);
			}
			e.preventDefault();
			return false;
		});

		$(".mobile-tab").on("click", function(e){
			if($(this).hasClass('mobile-tab-active')) {
				return false;
			} else {
				var target = $(this);
				setTimeout(function() {
					$('html, body').stop().animate({
					scrollTop: $(target).offset().top - 68
					}, 777);
				}, 200);
			}
		});
	/*-----------------------------------------*/
	/*------------------Табы на jQuery--------------*/
	var size = $('.product-active .size-row-active .size').text();
	var productName = $('.product-active .product-name').first().text();
	$(".tab").click(function(){
		$(".tab").removeClass("tab-active").eq($(this).index()).addClass('tab-active');
		$(".product-wrap").removeClass('product-active').eq($(this).index()).addClass('product-active');
		size = $('.product-active .size-row-active .size').text();
		productName = $('.product-active .product-name').first().text();
	});
	$(".mobile-tab").click(function(){
		if($(this).hasClass('mobile-tab-active')) {
			$(".mobile-tab").removeClass("mobile-tab-active");
			$(".product-wrap").removeClass('product-active');
		} else {
			$(".mobile-tab").removeClass("mobile-tab-active");
			$(".product-wrap").removeClass('product-active');
			$(this).addClass('mobile-tab-active').next('.product-wrap').addClass('product-active');
			size = $('.product-active .size-row-active .size').text();
			productName = $('.product-active .product-name').first().text();
		}
	});
	$(".size-row").click(function(){
		$(this).toggleClass('size-row-active');
		// var price = 0;
				size = "";
		$('.product-active .size-row-active .size').each(function() {
			// price += Number($(this).attr('data-price'));
			size += $(this).text() + "<br/>";
		});
		// $('.product-active .price').text(price);
	});
	/*--------------------------------------------*/
	/*------------------Попапы--------------------*/
	$('.fancybox').fancybox({
		maxWidth: 400,
		padding: 0,
		helpers: {
			overlay: {
				closeClick: true,
				locked: false
			}
		}
	});
	$('.politic').fancybox({
		maxWidth: 600,
		padding: 0,
		helpers: {
			overlay: {
				closeClick: true,
				locked: false
			}
		}
	});
	$('.map').fancybox({
		maxWidth: 900,
		padding: 0,
		helpers: {
			overlay: {
				closeClick: true,
				locked: false
			}
		}
	});
	/*--------------------------------------------*/
	/*----------------Мобильное меню-------------*/
	$('.mobile-menu-btn').click(function(){
		$('.overlay').toggleClass('overlay-active');
		$(".mobile-menu__wrap").toggleClass('show-menu');
	});
	$('.close-menu').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu__wrap").removeClass('show-menu');
	});
	$('.overlay').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu__wrap").removeClass('show-menu');
	});
	$('.mobile-menu__item a').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu__wrap").removeClass('show-menu');
	});
	/*--------------------------------------------*/
	/*------------------Белая шапка---------------*/
	$(window).scroll(function(){
		setTimeout(function(){
			if(window.pageYOffset > 50) {
				$('.header').addClass('header-white');
			} else {
				$('.header').removeClass('header-white');
			}
		}, 500);
	})
	/*--------------------------------------------*/
	/*--------------Заказаз товара----------------*/
	$('.order').click(function() {
		$('.popup-order').show();
		$('.popup-order__title span').text(productName);
		$('.popup-order__size span').html(size);
		$('input[name="product"]').val(productName);
		$('input[name="size"]').val(size);
	});
	$(document).on('click', '.fancybox-close', function() {
		$('.popup-order').hide();
		$('input[name="product"]').val("");
		$('input[name="size"]').val("");
		$(".fancybox-inner").removeClass('fancybox-inner-height');
	});
	/*--------------------------------------------*/
	/*----------------Формы----------------------*/
		$(document).on('submit', '.form-ajax', function(){
				var form = $(this);
				var action = form.attr('action');
				var formId = form.attr('id');
				var formData = new FormData(form.get(0));
				$.ajax({
					url: action,
					type: 'post',
					data: formData,
					contentType: false,
					processData: false,
					success: function(data){
						form.parent(".form-wrap").hide();
						$(".success[data-form=" + formId + "]").show();
						$(".fancybox-inner").addClass('fancybox-inner-height');
					}
				});
				return false;
			});
	/*------------------------------------------*/ 
});